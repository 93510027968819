
  .disabled_div{
    pointer-events: none;
    opacity: 0.5;
  }
  
  .wave_playButton {
    width: 100px;
    height: 38px;
    padding: 10px 0px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.384);
    font-size: 80%;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: all 0.3s ease-out;
  }
  
  .wave_playButton_active{
    background: #30b8ee7e;
  }
  
  .wave_playButton:hover {
    transition: all 0.3s ease-out;
    background: #30b8ee7e;
    border-color: #643a16;
    cursor: pointer;
  }
  
  .wave_play{
    width: 15px;
  }
  
  .playButton {
    padding: 12px;
    border-radius: 100px;
    cursor: pointer;
    border-color: #5a5999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    transition: transform 0.3s ease-out;
  }
  .playButton:hover {
    transition: transform 0.3s ease-out;
    transform: scale(1.01);
    border-color: #643a16;
    box-shadow: 0px 0px 3px 0px #020425;
    cursor: pointer;
  }
  
  .voices_waveandbuttons_container{
    background: rgb(5,17,70);
  background: linear-gradient(90deg, rgba(5,17,70,1) 0%, rgba(34,51,120,1) 49%, rgba(5,17,70,1) 100%);
  border-radius: 20px;
  }
  
  .voices_wavecontainer{
    margin: 40px 30px 40px 30px;
    width: 95%;
    border-radius: 10px;
    background: rgba(8, 2, 2, 0.099);
  }
  
  #waveform{
    width: 100%;
  }
  #waveform_timeline{
    width: 100%;
  }
  .voices_wave_buttonscontainer{
    width: 100%;
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    margin:-10px 0px 30px 0px;
  }
  
  .voices_wave_popoverdiv{
    padding: 10px;
  }
  
  .wavesurfer-handle{
    border-style: solid;
    border-color: #5FE1FA;
    border-width: 0px 3px;
  }
  
  .waveconfirm_class {
    width: 100px;
    height: 38px;
    padding: 10px 0px;
    border-radius: 100px;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: all 0.3s ease-out;
  }
  
  